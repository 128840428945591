import React, { useReducer, useState } from "react";
import { graphql } from "gatsby";
import Header from "../../header/header.js";
import { Helmet } from "react-helmet"


import Vimeo from '@u-wave/react-vimeo';
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing-3';
import { GatsbyImage } from "gatsby-plugin-image";

import "./work.scss";
import playButton from "../../images/play.png";

const WorksModal = ({ data }) => (
  <ModalRoutingContext.Consumer>
    {({ modal,  _/* closeTo */ }) => {
      return (
        <div>
          {modal ? (
            <div>
              <WorksPage data={data} isModal={true}/>
            </div>
          ) : (
            <div className="site-wrapper">
              <Header/>
              <WorksPage data={data} isModal={false}/>
            </div>
          )}
        </div>
      )
    }}
  </ModalRoutingContext.Consumer>
)

export default WorksModal;

function WorksPage({
  data,
  isModal
}) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark;

  // presense of a src fied indicates that we're treying trying 
  // to render a cloud, obviously :)
  if (frontmatter.src) {
    return <CloudPage src={frontmatter.src}/>
  }

  const media = frontmatter['work_media'];
  const hasCredits = frontmatter && frontmatter.credits && frontmatter.credits.length >= 1;

  return (
    <div className={`site-wrapper work-page-wrapper ${isModal ? "modal" : ""}`}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{frontmatter.title}</title>
      </Helmet>
      <Gallery media={media}/>
      <div className="work-details">
        <h1 className="title">{frontmatter.title}</h1>
        <p className="subtitle">{frontmatter.subtitle}</p>
        <div
          className="work-page-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
        {
          hasCredits && 
          <div className="credits-wrapper">
            {
              frontmatter.credits.map(credit => (
                <div className="credit-item" key={credit.credit}>
                  <p className="title">{credit.credit}</p>
                  <p className="subtitle">{credit.name}</p>
                </div>
              ))
            }
          </div>
        }
      </div>
    </div>
  )
}

function CloudPage({ src }) {
  return <div className="cloud-page-wrapper">
    <GatsbyImage 
      key={src.publicURL}
      image={src.childImageSharp.gatsbyImageData}
      alt="Cloud"
      loading="eager"
      width={1000}
    />
  </div>
}

function Gallery({ media }) {

  const [ mediaIndex, setMediaIndex ] = useState(0);
  return (
    <div className="gallery">
      <MainMedia mediaItem={media[mediaIndex]}/>
      { media.length >= 2 &&
        <div className={"media-selector" + (media.length == 2 ? " two-items": "")}>
          {
            media && media.map((mediaItem, index) => (
              <MediaPickerItem 
                mediaItem={mediaItem} 
                isSelected={index == mediaIndex}
                onClick={() => setMediaIndex(index)}
                key={mediaItem.src && mediaItem.src.publicURL || mediaItem.video_src}
              />
            ))
          }
        </div>
      }
    </div>
  )
}

function MainMedia({ mediaItem }) {

  const isVideo = !!(mediaItem && mediaItem.video_src);
  const isGif   = !isVideo && !mediaItem.src.childImageSharp;

  let imageComponent = !isVideo && <img src={mediaItem.src.publicURL}/>
  if (!isGif && !isVideo) {
    imageComponent =  <GatsbyImage 
                        key={mediaItem.src.publicURL}
                        image={mediaItem.src.childImageSharp.big}
                        alt="Main image"
                        loading="eager"
                      />
  }

  return (
    <div className={`main-media ${isVideo ? "video" : ""}`}>
        { mediaItem && (
            mediaItem.video_src ?
              <VimeoWrapper 
                key={mediaItem.video_src} 
                video={mediaItem.video_src} 
                width="600px"
                
              /> : imageComponent
        ) }
    </div>
  )
}

function MediaPickerItem({ mediaItem, isSelected, index, onClick }) {

  const isVideo = !!mediaItem.video_src

  // childImageSharp will be absent if the image is a GIF.
  const isGif = !isVideo && !mediaItem.src.childImageSharp;
  let imageComponent = !isVideo && <img src={mediaItem.src.publicURL}/>
  if (!isGif && !isVideo) {
    imageComponent =  <GatsbyImage 
                        key={mediaItem.src.publicURL}
                        image={mediaItem.src.childImageSharp.thumb}
                        alt="Thumbnail image"
                      />
  }

  return (
    <div className={`media-selector-option${isSelected ? " selected" : ""}`} onClick={onClick}>
      { isVideo ?
        <div className="video-thumb">
          <img src={playButton}/>
        </div>
        : imageComponent
      }
    </div>
  )
}

function VimeoWrapper({ video }) {
  return (
    <div className="vimeo-wrapper">
      <Vimeo video={video} responsive onError={() => { console.log("AHHHHHHH!!") }}/>
    </div>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        subtitle
        credits {
          credit
          name
        }
        src {
          id
          publicURL
          childImageSharp {
            gatsbyImageData(
              width: 2000
              placeholder: DOMINANT_COLOR
              formats: [PNG]
            )
          }
        }
        work_media {
          src {
            id
            publicURL
            childImageSharp {
              thumb:gatsbyImageData(
                width: 200
                placeholder: DOMINANT_COLOR
                formats: [PNG]
              )
              big:gatsbyImageData(
                width: 900
                placeholder: DOMINANT_COLOR
                formats: [PNG]
              )
            }
          }
          video_src
        }
      }
    }
  }
`